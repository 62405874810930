import "./styles.scss"
import "rc-slider/assets/index.css"

import Slider from "rc-slider"
import React, { useState } from "react"
import { AnimatePresence } from "framer-motion"

import Input from "components/Input"
import Checkbox from "components/Checkbox"
import PatternsModal from "components/PatternsModal"
import OptionsMobile from "./components/OptionsMobile"
import ColorVariantsModal from "./components/ColorVariantsModal"
import TextureVariantsModal from "components/TextureVariantsModal"

import { useConfig } from "context/config"

import IcoInfo from "assets/icons/info.svg"
import ChangeArrowsIcon from "assets/icons/config/ChangeArrowsIcon"

const ConfigPanel = ({ onReset }) => {
  const {
    scale,
    saturation,
    brightness,
    hue,
    current_wallpaper,
    sizes,
    free_stock,
    setScale,
    setSaturation,
    setBrightness,
    setHue,
    setSizes,
    changeQueryUrl,
    setCurrentWallpaper,
    setFreeStock,
    setVideoModal,
  } = useConfig()

  const [color_variant_modal, setColorVariantModal] = useState(false)
  const [patterns_modal, setPatternsModal] = useState(false)
  const [texture_variant_modal, setTextureVariantModal] = useState(false)
  const [size_width_error, setSizeWidthError] = useState(null)
  const [size_height_error, setSizeHeightError] = useState(null)

  const options_list_data = [
    {
      label: "Nazwa wzoru*",
      value: current_wallpaper?.product?.name,
      // sub_value: "Nazwa kolekcji/rok",
      btn_label: "Zmień wzór",
      onClick: () => setPatternsModal(true),
    },
    {
      label: "Wersja kolorystyczna*",
      value: current_wallpaper?.variation?.label,
      btn_label: "Zmień kolor",
      onClick: () => setColorVariantModal(true),
    },
    {
      label: "Tekstura tapety*",
      value: current_wallpaper?.texture?.title,
      btn_label: "Zmień teksturę",
      onClick: () => setTextureVariantModal(true),
    },
    {
      label: "Materiały",
      value: "Winyl na flizelinie",
    },
  ]

  const slider_items = [
    {
      label: "Skala",
      minmax: [100, 130],
      value: scale,
      value_suffix: "%",
      onChange: value => {
        setScale(value)
        changeQueryUrl("scale", value)
      },
    },
    {
      label: "Nasycenie",
      minmax: [-100, 100],
      value: saturation,
      onChange: value => {
        setSaturation(value)
        changeQueryUrl("saturation", value)
      },
      startPoint: 0,
    },
    {
      label: "Jasność",
      minmax: [-75, 75],
      value: brightness,
      onChange: value => {
        setBrightness(value)
        changeQueryUrl("brightness", value)
      },
      label_value: brightness * 2,
      startPoint: 0,
    },
    {
      label: "Barwa",
      minmax: [-180, 180],
      value: hue,
      onChange: value => {
        setHue(value)
        changeQueryUrl("hue", value)
      },
      startPoint: 0,
    },
  ]

  const onInputChange = (type, value) => {
    const max_width = 400
    const max_height = 300

    if (type === "width") {
      if (value > max_width) {
        setSizes(prev => [max_width, prev[1]])
        changeQueryUrl("sizes", JSON.stringify([max_width, sizes[1]]))
        setSizeWidthError("Max. 400 cm")
      } else {
        setSizes(prev => [value ? parseFloat(value) : null, prev[1]])
        changeQueryUrl(
          "sizes",
          JSON.stringify([value ? parseFloat(value) : null, sizes[1]])
        )
      }
    } else {
      if (value > max_height) {
        setSizes(prev => [prev[0], max_height])
        changeQueryUrl("sizes", JSON.stringify([sizes[0], max_height]))
        setSizeHeightError("Max. 300 cm")
      } else {
        setSizes(prev => [prev[0], value ? parseFloat(value) : null])
        changeQueryUrl(
          "sizes",
          JSON.stringify([sizes[0], value ? parseFloat(value) : null])
        )
      }
    }
  }

  const onInputBlur = type => {
    const min_width = 25
    const min_height = 25

    if (type === "width") {
      if (sizes[0] < min_width) {
        setSizes(prev => [min_width, prev[1]])
        setSizeWidthError("Min. 25 cm")
      } else {
        setSizeWidthError(null)
      }
    } else {
      if (sizes[1] < min_height) {
        setSizes(prev => [prev[0], min_height])
        setSizeHeightError("Min. 25 cm")
      } else {
        setSizeHeightError(null)
      }
    }
  }

  const freeStockHandler = () => {
    if (!!!free_stock) {
      setFreeStock(true)
      changeQueryUrl("free_stock", "true")
    } else {
      setFreeStock(false)
      changeQueryUrl("free_stock", undefined)
    }
  }

  const dimensions_data = {
    label: "Wymiary",
    sizes,
    free_stock,
    freeStockHandler,
    onInputBlur,
    onInputChange,
    size_width_error,
    size_height_error,
    type: "dimensions",
  }

  return (
    <div className="config-panel">
      <div className="config-panel__header">
        <button onClick={() => setVideoModal(true)}>
          Dowiedz się jak działa konfigurator
        </button>
      </div>
      <OptionsMobile
        basic_data={options_list_data}
        slider_data={slider_items}
        onReset={onReset}
        dimensions_data={dimensions_data}
      />
      <div className="config-panel__options">
        {options_list_data?.map(
          ({ label, value, sub_value, btn_label, onClick }, index) => (
            <div key={index} className="config-panel__options__option">
              <strong>{label}</strong>
              <span>
                {value} {!!sub_value && <small>{sub_value}</small>}
              </span>
              {!!btn_label && (
                <button onClick={onClick}>
                  {btn_label} <ChangeArrowsIcon />
                </button>
              )}
            </div>
          )
        )}
        <div className="config-panel__options__option config-panel__options__option--dimension">
          <strong>
            Wymiary{" "}
            <div>
              <img src={IcoInfo} alt="" />
              <strong>
                Przy wycenie, ze względów produkcyjnych, szerokość oraz wysokość
                zaokrąglamy do 10 cm.
              </strong>
            </div>
          </strong>
          <div className="config-panel__options__option__inputs">
            <div className="config-panel__options__option__input">
              <Input
                placeholder={sizes[0]}
                value={sizes[0]}
                onBlur={() => onInputBlur("width")}
                onChange={e => onInputChange("width", e.target.value)}
                onKeyDown={e => {
                  if (e.key === "Enter") {
                    e.stopPropagation()
                    onInputBlur("width")
                    onInputChange("width", e.target.value)
                    e?.target?.blur()
                  }
                }}
              />
              <small>
                Szerokość (cm){" "}
                {!!size_width_error && (
                  <small style={{ color: "red" }}>{size_width_error}</small>
                )}
              </small>
            </div>
            <span>x</span>
            <div className="config-panel__options__option__input">
              <Input
                placeholder={sizes[1]}
                value={sizes[1]}
                onBlur={() => onInputBlur("height")}
                onChange={e => onInputChange("height", e.target.value)}
                onKeyDown={e => {
                  if (e.key === "Enter") {
                    e.stopPropagation()
                    onInputBlur("height")
                    onInputChange("height", e.target.value)
                    e?.target?.blur()
                  }
                }}
              />
              <small>
                Wysokość (cm){" "}
                {!!size_height_error && (
                  <small style={{ color: "red" }}>{size_height_error}</small>
                )}
              </small>
            </div>
          </div>
          <div className="config-panel__options__option__checkbox">
            <Checkbox checked={!!free_stock} onChange={freeStockHandler}>
              Dodaj darmowy zapas do wymiaru
            </Checkbox>
            <div>
              <img src={IcoInfo} alt="" />
              <strong>
                Bezpłatne dodanie 5 cm
                <br />
                na szerokość i 5 cm na wysokość.
              </strong>
            </div>
          </div>
        </div>
        <div className="config-panel__options__option__sliders">
          {slider_items?.map(
            (
              {
                label,
                value,
                onChange,
                startPoint,
                value_suffix,
                minmax,
                label_value,
              },
              index
            ) => {
              const range = minmax?.[1] - minmax?.[0]
              const normalizedValue = value - minmax?.[0]
              const percentage = (normalizedValue / range) * 100

              return (
                <div
                  key={index}
                  className="config-panel__options__option__sliders__item"
                >
                  <span>{label}</span>
                  <div className="config-panel__options__option__sliders__item__wrapper">
                    <Slider
                      min={minmax?.[0]}
                      max={minmax?.[1]}
                      step={1}
                      value={value}
                      onChange={onChange}
                      startPoint={startPoint}
                    />
                    <div
                      className="slider-label"
                      style={{ left: `${percentage}%` }}
                    >
                      {`${label_value || value} ${
                        value_suffix ? value_suffix : ""
                      }`}
                    </div>
                  </div>
                </div>
              )
            }
          )}
        </div>
      </div>
      <AnimatePresence mode="wait">
        {!!color_variant_modal && (
          <ColorVariantsModal onClose={() => setColorVariantModal(false)} />
        )}
      </AnimatePresence>
      <AnimatePresence mode="wait">
        {!!patterns_modal && (
          <PatternsModal onClose={() => setPatternsModal(false)} />
        )}
      </AnimatePresence>
      <AnimatePresence mode="wait">
        {!!texture_variant_modal && (
          <TextureVariantsModal
            current_wallpaper={current_wallpaper}
            setCurrentWallpaper={setCurrentWallpaper}
            onClose={() => setTextureVariantModal(false)}
            changeQueryUrl={changeQueryUrl}
          />
        )}
      </AnimatePresence>
    </div>
  )
}

export default ConfigPanel
