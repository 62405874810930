/* eslint-disable jsx-a11y/control-has-associated-label */

import "./styles.scss"

import React, { useState, useEffect, useCallback } from "react"
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch"

import RotateVerticalIcon from "./components/RotateVerticalIcon"
import RotateHorizontalIcon from "./components/RotateHorizontalIcon"

import { useConfig } from "context/config"
import priceFormat from "utils/priceFormat"

import SplitWallpaperIcon from "assets/icons/split-wallpaper.svg"

const ConfigPreview = React.forwardRef(({ final_price }, ref) => {
  const {
    current_wallpaper,
    sizes,
    scale,
    saturation,
    brightness,
    hue,
    show_split,
    setShowSplit,
    changeQueryUrl,
    rorate_horizontal,
    rorate_vertical,
    setRotateHorizontal,
    setRotateVertical,
    position,
    setPosition,
    setVideoModal,
  } = useConfig()

  const [split_position, setSplitPosition] = useState(0)

  const [formatted_width, setFormattedWidth] = useState(sizes?.[0])
  const [formatted_height, setFormattedHeight] = useState(sizes?.[1])
  const [preview_price, setPreviewPrice] = useState(true)
  const [screen_width, setScreenWidth] = useState(null)
  const [min_ratio, setMinRatio] = useState(null)
  const [scale_init, setScaleInit] = useState(true)

  function generateArray(number) {
    const result = []
    const baseValue = 100

    const wholePart = Math.round(number)
    const decimalPart = number - wholePart

    for (let i = 0; i < wholePart; i++) {
      result.push(baseValue)
    }

    if (decimalPart > 0) {
      result.push(baseValue * decimalPart)
    }

    if (result[result.length - 1] < 25) {
      result[result.length - 2] = Math.round(
        100 - 25 + result[result.length - 1]
      )
      result[result.length - 1] = 25
    } else {
      if (number % 1) {
        result[result.length - 1] = Math.round((number % 1) * 100)
      }
    }

    if (number < 1) {
      result[0] = number * 100
    }

    return result
  }

  useEffect(() => {
    const container = document.querySelector(
      ".config-preview__wallpaper__split"
    )

    if (!!!container) return

    const wrapper = document.querySelector(".react-transform-wrapper")

    const containerWidth = wrapper?.offsetWidth // Pobiera szerokość kontenera
    const numberOfLines = sizes?.[0] / 100 // Oblicza liczbę linii

    // Czyści istniejące linie
    container.innerHTML = ""

    // Tworzy nowe linie i dodaje je do kontenera
    let lines = []

    for (let i = 0; i < numberOfLines; i++) {
      let left = i * (containerWidth / numberOfLines)
      if (i === Math.floor(numberOfLines)) {
        if (numberOfLines - i < 0.25) {
          left =
            containerWidth -
            (container?.offsetWidth - 0.94 * container?.offsetWidth)
        }
      }

      const line = document.createElement("div")
      line.className = "config-preview__wallpaper__split__line"
      line.style.left = `${left}px`
      container.appendChild(line)

      lines.push(left)
    }

    const dimensions_array = generateArray(numberOfLines)

    lines?.forEach((item, index) => {
      const anchor = item + 10

      const text = document.createElement("span")
      text.innerHTML = `${Math.floor(dimensions_array?.[index])} cm`
      text.style.position = "absolute"
      text.style.left = `${anchor}px`
      text.style.bottom = `${-25}px`

      container.appendChild(text)
    })
  }, [formatted_width, show_split]) //eslint-disable-line

  useEffect(() => {
    var containerElement = document.getElementsByClassName("con-container")?.[0]

    // Wymiary diva
    var divWidth = sizes?.[0]
    var divHeight = sizes?.[1]

    // Wymiary kontenera
    var containerWidth = containerElement.offsetWidth
    var containerHeight = containerElement.offsetHeight

    // Obliczanie proporcji
    var widthRatio = containerWidth / divWidth
    var heightRatio = containerHeight / divHeight

    var minRatio = Math.min(widthRatio, heightRatio)

    setFormattedWidth(divWidth * minRatio)
    setFormattedHeight(divHeight * minRatio)
    setMinRatio(widthRatio >= heightRatio)
  }, [sizes, screen_width])

  useEffect(() => {
    if (!!!ref?.current) return

    var containerElement = document.getElementsByClassName(
      "react-transform-wrapper"
    )?.[0]

    if (!!scale_init) {
      ref?.current?.setTransform(
        ref?.current?.instance?.transformState?.positionX,
        ref?.current?.instance?.transformState?.positionY,
        scale / 100
      )
    } else {
      ref?.current?.zoomToElement(containerElement, scale / 100)
    }

    setScaleInit(false)
    // eslint-disable-next-line
  }, [scale])

  useEffect(() => {
    //Ustawianie pozycji dzielenia tapet
    const element = document?.getElementsByClassName(
      "react-transform-wrapper"
    )?.[0]

    setSplitPosition(element.offsetLeft)
  }, [formatted_width, formatted_height])

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY
      setPreviewPrice(!(scrollY > 320))
    }

    window.addEventListener("scroll", handleScroll)

    // Cleanup - usuwa event listener po odmontowaniu komponentu
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const handleWindowResize = useCallback(event => {
    setScreenWidth(window.innerWidth)
  }, [])

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize)
    return () => {
      window.removeEventListener("resize", handleWindowResize)
    }
  }, [handleWindowResize])

  return (
    <div className="config-preview">
      <div className="config-preview__header">
        <div className="config-preview__header__col">
          <button
            onClick={() => {
              setRotateVertical(prev => !prev)
              changeQueryUrl("rorate_vertical", !rorate_vertical)
            }}
            className={`${!!rorate_vertical ? "active" : ""}`}
          >
            <RotateVerticalIcon />
          </button>
          <button
            onClick={() => {
              setRotateHorizontal(prev => !prev)
              changeQueryUrl("rorate_horizontal", !rorate_horizontal)
            }}
            className={`${!!rorate_horizontal ? "active" : ""}`}
          >
            <RotateHorizontalIcon />
          </button>
          <span>Obróć wzór</span>
        </div>
        <div className="config-preview__header__col">
          <button
            onClick={() => {
              setShowSplit(prev => !prev)
              changeQueryUrl("show_split", !show_split)
            }}
          >
            <img src={SplitWallpaperIcon} alt="" />
            <span>
              {!!show_split
                ? "Ukryj dzielenie tapety"
                : "Pokaż dzielenie tapety"}
            </span>
          </button>
        </div>
        <div className="config-preview__header__col">
          <button onClick={() => setVideoModal(true)}>
            Dowiedz się jak
            <br />
            działa konfigurator
          </button>
        </div>
      </div>

      <div
        className={`config-preview__wallpaper ${
          !!rorate_vertical && !!rorate_horizontal
            ? "config-preview__wallpaper--both"
            : !!rorate_vertical
            ? "config-preview__wallpaper--vertical"
            : !!rorate_horizontal
            ? "config-preview__wallpaper--horizontal"
            : ""
        }`}
      >
        <div
          className="con-container"
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            backgroundColor: "white",
          }}
        >
          {!!show_split && (
            <div
              className="config-preview__wallpaper__split"
              style={{
                left: split_position,
              }}
            />
          )}

          <TransformWrapper
            style={{
              width: formatted_width,
              height: formatted_height,
              position: "relative",
            }}
            disablePadding={true}
            doubleClick={{ disabled: true }}
            wheel={{
              disabled: true,
            }}
            pinch={{
              disabled: true,
            }}
            panning={{
              disabled:
                scale === 100 && !!(sizes?.[0] === 400 && sizes?.[1] === 300),
            }}
            onPanningStop={e => {
              setPosition([e?.state?.positionX, e?.state?.positionY])
              changeQueryUrl(
                "position",
                JSON.stringify([e?.state?.positionX, e?.state?.positionY])
              )
            }}
            ref={ref}
            initialPositionX={position[0]}
            initialPositionY={position[1]}
          >
            <TransformComponent
              contentClass="transform-component"
              wrapperStyle={{
                width: formatted_width,
                height: formatted_height,
                cursor:
                  scale > 100 || !(sizes?.[0] === 400 && sizes?.[1] === 300)
                    ? "grab"
                    : "unset",
              }}
              contentStyle={{
                width: !!!min_ratio ? "100%" : null,
                height: !!!min_ratio ? null : "100%",
              }}
            >
              <div
                style={{
                  width: !!!min_ratio ? "100%" : null,
                  height: !!!min_ratio ? null : "100%",
                }}
              >
                <img
                  id="img"
                  src={current_wallpaper?.variation?.image}
                  alt=""
                  style={{
                    width: !!!min_ratio ? "100%" : null,
                    height: !!!min_ratio ? null : "100%",
                    cursor: "grab",
                    filter: `saturate(${100 + saturation}%) brightness(${
                      ((brightness > 0 ? brightness + 170 : brightness + 150) /
                        300) *
                        (200 - 30) +
                      15
                    }%) hue-rotate(${hue}deg)`,
                  }}
                />
              </div>
            </TransformComponent>
          </TransformWrapper>
        </div>
      </div>

      {!!preview_price && (
        <span className="config-preview__price">
          <span>Cena skonfigurowanej tapety</span>
          <strong>
            {priceFormat(final_price)} zł{" "}
            <span>
              / {current_wallpaper?.variation?.price} zł/m
              <sup>2</sup>
            </span>
          </strong>
        </span>
      )}
    </div>
  )
})

// const ConfigPreview = ({ final_price }) => {

// }

export default ConfigPreview
